<template>
  <el-dialog :title="isEdit?'编辑':'新增'" width="600px" :visible="show" @close="close" :close-on-click-modal="false">
    <el-form ref="form" :model="form" label-width="90px" :rules="rules">
      <el-form-item label="字典类型：">
        <el-input v-model="form.dictionaryType" class="inputWidth" disabled></el-input>
      </el-form-item>
      <el-form-item label="字典标签：" prop="dictionaryLabel">
        <el-input v-model="form.dictionaryLabel" class="inputWidth"></el-input>
      </el-form-item>
      <el-form-item label="字典键值：" prop="dictionaryValue">
        <el-input v-model="form.dictionaryValue" class="inputWidth"></el-input>
      </el-form-item>
      <el-form-item label="字典排序：" prop="dictionarySort">
        <el-input placeholder="请输入" v-model="form.dictionarySort" clearable type="number"></el-input>
      </el-form-item>
      <el-form-item label="是否默认：">
        <!-- <el-switch v-model="form.isDefault">
        </el-switch> -->

        <el-radio-group v-model="form.isDefault">
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="状态：" prop="status">
        <el-radio-group v-model="form.status">
          <el-radio label="0">启用</el-radio>
          <el-radio label="1">禁用</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="图标：" prop="iconUrl">
        <image-upload :url.sync="form.iconUrl" @success="handleAvatarSuccess" :params="{ businessType: 'system_icon' }" :fileSize="2" fileType="image" />
      </el-form-item>
      <el-form-item label="备注：">
        <el-input class="inputWidth" type="textarea" :rows="2" placeholder="请输入" v-model="form.remark">
        </el-input>
      </el-form-item>

    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="onSubmit">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import Api from "@/api/devops/dictionary";
import imageUpload from "@/components/imageUpload";
export default {
  components: { imageUpload },
  props: {
    show: {
      default: false,
      type: Boolean,
    },
    detail: {
      default: () => {},
      type: Object,
    },
    isEdit: { type: Boolean, default: false },
    dictionaryType: {
      default: "",
      type: String,
    },
  },
  watch: {
    show(val) {
      if (val) {
        this.isEdit = !_.isEmpty(this.detail);
        this.form = {
          ...(this.isEdit ? this.detail : { isDefault: false, status: "0" }),
          dictionaryType: this.dictionaryType,
        };
      } else {
        this.$refs["form"].resetFields();
      }
    },
    dictionaryType(val) {
      this.form.dictionaryType = val;
    },
  },

  data() {
    return {
      form: {},
      rules: {
        dictionaryLabel: [
          { required: true, message: "请输入字典标签", trigger: "blur" },
        ],
        dictionaryValue: [
          { required: true, message: "请输入字典键值", trigger: "blur" },
        ],
        dictionarySort: [
          { required: true, message: "请输入字典排序", trigger: "blur" },
        ],
        status: [
          { required: true, message: "请输入字典状态", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {},
  methods: {
    onSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.fetch();
        } else {
          return false;
        }
      });
    },

    async fetch() {
      let params = {
        ...this.form,
      };
      const fn = this.form.id ? Api.updateData : Api.addData;
      let res = await fn(params);
      this.form.label = "";
      this.$msg.success("操作成功");
      this.close();
    },
    close() {
      this.$emit("close");
    },
    handleAvatarSuccess(res, file) {
      const { ossUrl } = res.data;
      this.$set(this.form, "iconUrl", ossUrl);
    },
  },
};
</script>
<style lang="scss" scoped>
</style>