
<template>
  <div class="h100">
    <common-layout>
      <template #header>
        <el-form inline>
          <el-form-item label="字典类型">
            <el-select v-model="form.dictionaryType" placeholder="请选择" clearable @change="typeChange">
              <el-option v-for="item in dictionaryTypeList" :key="item.dictionaryId" :label="item.dictionaryName" :value="item.dictionaryType">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="字典标签">
            <el-input placeholder="请输入" v-model="form.dictionaryLabel" clearable></el-input>
          </el-form-item>
          <el-form-item label="状态：">
            <el-select v-model="form.status" placeholder="请选择" clearable>
              <el-option v-for="item in stateOptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <template slot-scope="scope">
              <el-button type="primary" @click="getData">查询</el-button>
              <el-button type="primary" plain @click="handleAdd('add',scope.row)">新增</el-button>
              <el-button type="danger" plain @click="handleDeleteAll">删除</el-button>
            </template>
          </el-form-item>
        </el-form>
      </template>
      <template #default>
        <el-table ref="table" :data="tableData" v-loading="tableLoading" border highlight-current-row height="100%" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55" align="center" />
          <el-table-column prop="dictionaryLabel" label="字典标签" align="center" />
          <el-table-column prop="dictionaryValue" label="字典键值" align="center" />
          <el-table-column prop="isDefault" label="默认值" align="center">
            <template slot-scope="scope">
              <span :class="scope.row.isDefault==true ? 'enable-status' : 'disable-status'">{{ scope.row.isDefault==true ? '是' : '否' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="dictionarySort" label="字典排序" align="center" />
          <el-table-column prop="status" label="状态" align="center">
            <template slot-scope="scope">
              <span :class="scope.row.status=='0' ? 'enable-status' : 'disable-status'">{{ scope.row.status=="0" ? '已启用' : '已禁用' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="remark" label="备注" align="center" />
          <el-table-column prop="createdBy" label="创建账号" align="center" />
          <el-table-column prop="createTime" label="创建时间" align="center" />
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="handleAdd('edit',scope.row)"> 编辑 </el-button>
              <el-button type="text" @click="handleDelete(scope.row.id)"> 删除 </el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <template #footer>
        <el-pagination class="mt10" :page-size="page.pageSize" :current-page="page.pageNum" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange"
          layout="total, sizes, prev, pager, next, jumper"></el-pagination>
      </template>
    </common-layout>

    <add-modal :isEdit="isEdit" :show="addDrawer" @close="closeModal" :detail="rowData" :dictionaryType="type"></add-modal>
  </div>
</template>
<script>
import addModal from "./addModal";
import Api from "@/api/devops/dictionary";
export default {
  components: { addModal },

  computed: {
    dictionaryType() {
      return this.$route.query.dictionaryType;
    },
  },
  data() {
    return {
      form: {},
      rowData: null,
      tableData: [],
      selectionData: [],
      tableLoading: false,
      page: {
        pageNum: 1,
        pageSize: 20,
      },
      total: 0,
      addDrawer: false,
      isEdit: false,
      stateOptions: [
        {
          value: "0",
          label: "启用",
        },
        {
          value: "1",
          label: "禁用",
        },
      ],
      dictionaryTypeList: [],
      type: "",
    };
  },
  mounted() {
    this.getTypeList();
    this.type = this.dictionaryType;
    this.form.dictionaryType = this.dictionaryType;
    this.getData();
  },
  methods: {
    async getData() {
      let { pageSize, pageNum } = this.page;
      let { dictionaryType, dictionaryLabel, status } = this.form;
      let params = {
        current: pageNum,
        size: pageSize,
        dictionaryType,
        dictionaryLabel,
        status,
      };
      let { code, data } = await Api.getDataList(params);
      if (data.records) {
        this.tableData = data.records || [];
        this.total = data.total;
      }
    },
    async getTypeList() {
      let params = {
        current: 1,
        size: 2000,
      };
      let { code, data } = await Api.getTypeList(params);
      if (data.records) {
        this.dictionaryTypeList = data.records || [];
      }
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.page.pageNum = 1;
      this.getData();
    },
    handleCurrentChange(val) {
      this.page.pageNum = val;
      this.getData();
    },

    handleAdd(type, row) {
      this.addDrawer = true;
      switch (type) {
        case "add":
          this.rowData = {};
          this.isEdit = false;
          break;
        case "edit":
          this.isEdit = true;
          this.rowData = { ...row };
          break;
      }
    },
    handleEdit() {},
    handleDelete(row) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        Api.deData(row).then(() => {
          this.$msg.success("操作成功");
          this.getData();
        });
      });
    },
    handleDeleteAll() {
      let idList = this.selectionData.map((item) => item.id).join(",");
      this.handleDelete(idList);
    },
    closeModal() {
      this.addDrawer = false;
      this.getData();
    },
    handleSelectionChange(val) {
      this.selectionData = val;
    },
    typeChange(value) {
      this.dictionaryTypeList.forEach((item) => {
        if (item.dictionaryType === value) {
          this.type = item.dictionaryType;
        }
      });
      this.getData();
    },
  },
};
</script>
